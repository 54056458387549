import App from "../App.js"
import PostData from "./PostData.js";
import TopicData from "./TopicData.js";

class FeedData{
    static oldestNewest = 12;
    /** @typedef {number} FeedData~Error Errorcode 0=NONE, 1=BUSY, 2=STRINGLENGTH, 3=ARGTYPE*/
    static Errors = {
        NONE:0,
        BUSY:1,
        STRINGLENGTH:2,
        ARGTYPE:3,
        FAIL:4,
        NOTFOUND:5};


        constructor(topic){
            this.busy = false;
            this.loaded = false;
            this.toLoad = 0;
            this.ready = false;
            this.posts  = [];
            this.questionColorCount = [];
            this.colorschematicCount  = [];
        }

        loadFeed(user, topic, onFeedLoaded, onLoadError){
            //Errohandling
            if(this.busy)
                return FeedData.Errors.BUSY;
            try{
                //Abfrage an die Datenbank
                this.busy = true;
                this.loaded = false;
                this.toLoad = 0;
                this.ready = false;
                App.db_posts.child(topic.id).once("value").then(
                    (data) => this.onLoadFeed(user, data, onFeedLoaded)).catch((error) => this.onLoadFeedError(error, onLoadError));

            }
            catch(error){
                onLoadError(this, error);
                return FeedData.Errors.FAIL;
            }
            return FeedData.Errors.NONE;
        }

        onLoadFeed(user, feed, onFeedLoaded){
            feed.forEach((post) => this.loadPost(user, post));
            this.ready = true;
            this.onFeedLoaded = onFeedLoaded;
            this.checkFeedLoaded();
        }

        loadPost(user, postData){
            this.toLoad++;
            var post = new PostData(user, postData);
            if(post.text === null){
                this.toLoad--;
                return;
            }
            post.loadAuthor(() => this.onPostLoaded());
            this.posts.push(post);
        }

        // Einzelner Post geladen
        onPostLoaded(){
            this.toLoad--;
            this.checkFeedLoaded();
        }

        // Überprüft ob der Ganze Feed geladen ist
        checkFeedLoaded(){
            if(this.ready && this.toLoad === 0)
                if(this.onFeedLoaded){
                    this.sortFeed();
                    this.createStatistics();
                    this.busy = false;
                    this.loaded = true;
                    this.onFeedLoaded(this);
                }

        }

        // Fehler beim Laden
        onLoadFeedError(error, onLoadError) {
            //Abschluss der Datenbankabfrage
            this.busy = false;
            this.loaded = false;

            if(onLoadError)
                onLoadError(this, error); //Callback
        }

        // Erstellt die Statistiken
        createStatistics(){
            this.questionColorCount = [];
            for(let i = 0; i < TopicData.currentTopic.questionCount; i++){
                this.questionColorCount[i] = [];
                for(let index = 0; index < TopicData.currentTopic.questions[i].answers.length; index++){
                        (this.questionColorCount[i])[index] = {color:TopicData.currentTopic.questions[i].answers[index].color, count : 0};
                }
            }

            this.posts.forEach((post) => {
                if(post.ColorSchematicData){
                    let uid = 0;
                    let mul = 1;
                    for(let i in post.ColorSchematicData.index){
                        let index = post.ColorSchematicData.index[i];
                        if(!this.questionColorCount[i])
                            this.questionColorCount[i] = [];

                        if((this.questionColorCount[i])[index]){
                            (this.questionColorCount[i])[index].count++;
                        } else{
                            (this.questionColorCount[i])[index] = {color:TopicData.currentTopic.questions[i].answers[post.ColorSchematicData.index[i]].color, count : 1};

                        }
                        uid = uid + mul * index;
                        mul = mul * 10;
                    }
                    if(this.colorschematicCount[uid]){
                        this.colorschematicCount[uid].count++;
                    } else{
                        this.colorschematicCount[uid] = {schematic:post.ColorSchematicData, count:1};
                    }
                }
            });
            this.colorschematicCount = this.colorschematicCount.filter((e) => e !== null);
            this.colorschematicCount = this.colorschematicCount.sort((f, s) => s.count - f.count);
            this.questionColorCount = this.questionColorCount.sort((f, s) => s.count - f.count);
        }

        // Sortiert den Feed
        sortFeed(){
            let topLike = Array.from(this.posts);
            topLike.sort((f, s) => s.likes - f.likes);
            let newest = Array.from(this.posts);
            newest.sort(function(f, s) {
                if(!s.timeStamp){
                    return -f.timeStamp;
                }
                if(!f.timeStamp)
                    return 1;
                return s.timeStamp - f.timeStamp;
            });
            this.posts = [];

            let phase = "Top";
            let c = 10000;
            while(newest.length > 0 && c > 0){
                let post;
                c--;
                switch(phase){
                    case "Top":
                      post = topLike[0];
                      if(Date.now() - newest[0].timeStamp < 3600 * FeedData.oldestNewest * 1000)
                          phase = "Newest";
                      break;
                    case "Newest":
                      post = newest[0];
                      phase = "Top";
                      break;
                    default:
                      //nothing to do here
                      break;
                }
                topLike = topLike.filter((e) => e !== post);
                newest = newest.filter((e) => e !== post);
                this.posts.push(post);
            }
        }
}

export default FeedData
