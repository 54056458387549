import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';

//icons
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import PersonIcon from '@material-ui/icons/Person';
import {FaInfoCircle} from 'react-icons/fa';

import App from '../../App.js';

/** Komponente die die Navbar rendert. */
class Navbar extends React.Component {

    static buttonTitles = {
      INFO: 'Info',
      TOPIC: 'Thema',
      FEED: 'Feed',
      PROFILE: 'Profil'
    }

    static infoIcon() {
      return(<FaInfoCircle />);
    }

    static topicIcon() {
      return(<BubbleChartIcon />);
    }

    static feedIcon() {
      return(<DynamicFeedIcon />);
    }

    static profileIcon() {
      return(<PersonIcon />);
    }

  constructor(props) {
    super(props);
    this.buttonsData = [
      {
        title: Navbar.buttonTitles.INFO,
        tab: App.Tabs.INFO,
        icon: Navbar.infoIcon()
      },
      {
        title: Navbar.buttonTitles.TOPIC,
        tab: App.Tabs.TOPIC,
        icon: Navbar.topicIcon()
      },
      {
        title: Navbar.buttonTitles.FEED,
        tab: App.Tabs.FEED,
        icon: Navbar.feedIcon()
      },
      {
        title: Navbar.buttonTitles.PROFILE,
        tab: App.Tabs.PROFILE,
        icon: Navbar.profileIcon()
      }
    ]
  }

  render() {

    const renderNavbarButton = (button,index) => {
      return(
        <button key={index} data-testid={'button'+index} className="discoverButton-2 m-1" onClick={()=>{App.currentApp.setTab(button.tab)}}>
          {button.icon} {button.title}
        </button>
      );
    };

    return (
      <Container>
        <div className={'PrimaryColor fixed-bottom d-flex justify-content-around p-1'}>
          {this.buttonsData.map((button,index) => renderNavbarButton(button,index))}
        </div>
      </Container>
    );
  }
}


export default Navbar;
