import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import TopicData from "../FirebaseData/TopicData";

//icons
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

class ColorSchematic extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      colorSchematicData: props.colorSchematicData
    };
  }

  renderDot(color, index) {

    if(!this.state.colorSchematicData.index.length) {
      console.log('Cannot render color schematic becuase it is empty.');
      return;
    }

    const question = TopicData.currentTopic.questions[index];
    const questionTitle = question.title;
    const answerTitle = question.answers[this.state.colorSchematicData.index[index]].title;

    const popover = (
      <Popover id="popover-basic">
        <Popover.Title as="h3">{questionTitle}</Popover.Title>
        <Popover.Content>
          {answerTitle}
        </Popover.Content>
      </Popover>
    );

    return(
      <OverlayTrigger key={index} trigger={["hover", "focus"]} placement="bottom" overlay={popover}>
        <FiberManualRecordIcon key={index} style={{ color: color }} />
      </OverlayTrigger>
    );
  }

  render() {
    if(this.state.colorSchematicData) {
      return(
        <center>
          <div className="d-flex justify-content-center">
            {this.state.colorSchematicData.colors.map( (color, index) => this.renderDot(color, index) )}
          </div>
        </center>
      );
    }
    return(<></>);
  }

}

export default ColorSchematic;
