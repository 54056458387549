import React from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import App from '../App.js';
import Navbar from './Navbar/Navbar.js';
import GeneralData from '../FirebaseData/GeneralData.js';
import ChangeShortTextModal from './ChangeShortTextModal.js';

class InfoPage extends React.Component {

  static infotext = <p>
          Willkomme uf dr Discover App!<br/>
          Hie geits drum sech zumne bestimmte Thema ä Meinig z bilde und die när z teile. <br/>
          Da drzue chasch eifach paar Frage beantworte und dini Meinig aschliessend begründe. <br/><br/>

          Nachere Wuche wärde di beliebtiste Meinige zur Diskussion freigä. Du findsch d Biträg mit de Meinige im Feed zum vote.
  </p>;

  constructor(props) {
    super(props);
    this.state = {
      firstLogin: props.firstLogin,
    }
  }

  handleSaveUserName(name) {
    App.currentApp.state.user.setUserName(name)
    this.setState({newText: name});
  }

  render() {

    const variantDiscuss = <Container>
      <div className="infoBox">
        Momentan befinden wir uns in der Phase<br/>
        <h3><b>
          {GeneralData.state.phase}
        </b></h3>
        In dieser Phase können keine neuen Beiträge verfasst werden.
      </div>
      <button className="discoverButton"
      onClick={() => App.currentApp.setState({tab: App.Tabs.FEED})}>
        BELIEBTI BITRÄG DISKUTIERE
      </button>
    </Container>

    const variantVote = <Container>
      <div className="infoBox">
        Momentan befindä mir üs i dr Phase<br/>
        <h3><b>
          {GeneralData.state.phase}
        </b></h3>
      </div>
      <button className="discoverButton"
      onClick={() => App.currentApp.setState({tab: App.Tabs.TOPIC})}>
        Mini Meinig teile
      </button>
    </Container>

    return (
      <Container className="bannerContainer">
        <ChangeShortTextModal
          open = {this.state.firstLogin}
          disableClose = {true}
          title = {'Profil Name'}
          placeholder = {'Neuer Profilname'}
          handleSave={(name) => this.handleSaveUserName(name)}
        />
        <Row className="banner">
          <Col className="ml-5" md={4}>
            <h1>
              <b>DISCOVER<br/>APP</b>
            </h1>
          </Col>
        </Row>
          <center style={{marginTop: 150}}>
            {InfoPage.infotext}
            {GeneralData.state.isDiscussion()?variantDiscuss:variantVote}
          </center>
          <br/><br/>
          <br/><br/>
        <Navbar />
      </Container>
    );
  }
}

export default InfoPage;
