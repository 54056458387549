import App from "../App.js"

/** Klasse die generelle Daten enthält, ladet und in die Datenbank schreibt. */
class GeneralData {
    /**
     * Wird aufgerufen sobald der User die Daten von der Datenbank geladen hat.
     * @callback GeneralData~onGeneralLoaded
     * @param {GeneralData} data
     */

     /**
     * Wird aufgerufen sobald der User die Daten von der Datenbank geladen hat.
     * @callback GeneralData~onGeneralLoadError
     * @param {GeneralData} data
     * @param {Object} error
     */

    /** @typedef {number} GeneralData~Error Errorcode 0=NONE, 1=BUSY, 2=STRINGLENGTH, 3=ARGTYPE*/
    static Errors = {
        NONE:0,
        BUSY:1,
        STRINGLENGTH:2,
        ARGTYPE:3,
        FAIL:4};

    // Relative Referenzen zur Datenbank
    static topicRef = "topic";
    static phaseRef = "phase";

    /** @type {GeneralData} Generelle Daten. Statisch zugrefibar. */
    static state = null;

    static discussionPhaseLabel = 'Diskutieren';

    /**
     * Bereitet einen Generelle Daten zum Laden vor.
     */
    constructor() {
        /** @type {boolean} Benutzerdaten sind am Laden. */
        this.busy = false;
        /** @type {boolean} Benutzerdaten sind geladen. */
        this.loaded = false;
        /** @type {string} Aktives Thema. */
        this.topic = null;
        /** @type {string} Aktive Phase. */
        this.phase = null;
    }

     /**
     * Starten des Ladens der Benutzerdaten.
     * @param {GeneralData~onGeneralLoaded} onGeneralLoaded Callback für sobald die Daten geladen wurden
     * @param {GeneralData~onGeneralLoadError} onLoadError Callback falls ein Fehler auftritt
     * @returns {GeneralData~Error} Fehlercode
     */
    loadGeneral(onGeneralLoaded, onLoadError) {
        //Errohandling
        if(this.busy)
            return GeneralData.Errors.BUSY;
        try{
            this.db = App.db_general;
            //Abfrage an die Datenbank
            this.busy = true;
            this.loaded = false;
            GeneralData.state = null;
            // Datenbankabfrage
            this.db.once("value")
                .then((data) => this.onLoadGeneral(data, onGeneralLoaded)) // Success
                .catch((error) => this.onLoadGeneralError(error, onLoadError)); // Fail
        }
        catch(error){
            onLoadError(this, error);
            return GeneralData.Errors.FAIL;
        }
        return GeneralData.Errors.NONE;
    }

    // Generelle daten wurden geladen
    onLoadGeneral(data, onGeneralLoaded) {
        //Setzen der Grunddaten
        this.topic = data.child(GeneralData.topicRef).val();
        this.phase = data.child(GeneralData.phaseRef).val();

        this.busy = false;
        this.loaded = true;
        GeneralData.state = this;
        if(onGeneralLoaded)
            onGeneralLoaded(this); //Callback
    }

    // Fehler beim Laden
    onLoadGeneralError(error, onLoadError){
        //Abschluss der Datenbankabfrage
        this.busy = false;
        this.loaded = false;

        if(onLoadError)
            onLoadError(this, error); //Callback
    }

    /**
     * Überprüft ob
     * @returns {bool} Phase in Diskussion
     */
    isDiscussion() {
      return GeneralData.state.phase === GeneralData.discussionPhaseLabel;
    }
}

export default GeneralData
