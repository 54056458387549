import React from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';

import Navbar from './Navbar/Navbar.js';

class ErrorPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {app: props.app};
  }

  render() {
    return (
      <Container>
        <center>
          <h1>
            Ups, da ging etwas schief...
          </h1>
        </center>
      <Navbar app={this.state.app} divclass="bg-dark fixed-bottom" button="dark" />
      </Container>
    );
  }
}

export default ErrorPage;
