
/** Klasse die Antwortdaten enthält */
class AnswerData{
    // Relative Referenzen zur Datenbank
    static titleRef = "title";
    static colorRef = "color";

    /**
     * Ein DatenSnaphsot.
     * @see {@link https://firebase.google.com/docs/reference/js/firebase.database.DataSnapshot?hl=EN}
     * @typedef {Object} DataSnapshot 
     */

    /**
     * Liest die Antwort aus einem DataSnapshot
     * @param {DataSnapshot} answer Datensnapshot
     */
    constructor(answer){  
        this.id = answer.ref.getKey();
        this.title = answer.child(AnswerData.titleRef).val();
        this.color = answer.child(AnswerData.colorRef).val();
    }
}

export default AnswerData;