import AnswerData from "../FirebaseData/AnswerData.js";

/** Klasse die Fragendaten enthält */
class QuestionData{
    // Relative Referenzen zur Datenbank
    static titleRef = "title";
    static answerRef = "answers";

    /**
     * Ein DatenSnaphsot.
     * @see {@link https://firebase.google.com/docs/reference/js/firebase.database.DataSnapshot?hl=EN}
     * @typedef {Object} DataSnapshot 
     */

    /**
     * Liest die Frage und Antworten aus einem DataSnapshot
     * @param {DataSnapshot} questionData DatenSnapshot
     */
    constructor(questionData){
        // Setzen der Grunddaten
        /** @type {string} Titel der Frage. */
        this.title = questionData.child(QuestionData.titleRef).val();
        /** @type {string} Datenbank ID der Frage. */
        this.id = questionData.ref.getKey();
        // Setzen der Antworten
        var i = 0;
        /** @type {number} Antwort des User. */
        this.currentAnswer = -1;
        /** @type {Array.<AnswerData>} Alle Antworten zu der Frage. */
        this.answers = [];
        questionData.child(QuestionData.answerRef).forEach((answer) => { this.answers[i] = new AnswerData(answer); i++; });
    }
}

export default QuestionData