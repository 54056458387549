import React from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';

import Navbar from './Navbar/Navbar.js';
import ChangeShortTextModal from './ChangeShortTextModal.js';

import Post from './Post.js';
import App from '../App.js';

class Profil extends React.Component {

  static labels = {
    weeklyPostLabel: '\'s Wuchäbitrag:',
    noPostPlaceholder: 'Es git no ke Post vom Benutzer ',
  }

  constructor(props) {
    super(props);
    this.state = {
      user: props.user,
      canChange: props.canChange,
      changeTextModalOpen: [props.firstLogin,false],
      newText: ""
    }
  }

  componentWillUnmount() {
    App.currentApp.setState({profileUser: null});
  }

  renderOwnPost() {
    if(this.state.user.hasOpinion()) {
      return(
        <Post
          divclass=''
          showChange={true}
          onClickChange={()=>{App.currentApp.setTab(App.Tabs.TOPIC)}}
          open=''
          postData={this.state.user.post}
        />
      );
    }
    else {
      return(
        <Container>
          <Card style={App.cardStyle}>
            <Card.Body>
              <p>Es gibt noch keinen Post von {this.state.user.name}.</p>
            </Card.Body>
          </Card>
        </Container>
      );
    }
  }

  //{this.changeTextModal('Profil Name','Neuer Profilname',0)}

  handleSaveUserName(name) {
    App.currentApp.state.user.setUserName(name)
    this.setState({newText: name});
  }

  renderChangeUserNameButton() {
    if(this.state.canChange) {
      return (
        <p style={{textAlign:"right"}}>
          <ChangeShortTextModal
            showButton = {true}
            title = {'Profil Name'}
            placeholder = {'Neuer Profilname'}
            handleSave={(name) => this.handleSaveUserName(name)}
          />
        </p>
      );
    }
    return <></>;
  }

  renderUserNameCard() {
    return (
      <Card className="text-center" style={App.cardStyle}>
        <Card.Body>
          <h1>{this.state.user.name}</h1>
          {this.renderChangeUserNameButton()}
        </Card.Body>
      </Card>
    );
  }

  renderSymbolCard() {
    //TODO
    return;
    /*return(
      <Card style={{width:150,height:150}}className="text-center">
        <Card.Body>
          <p>Symbol</p>
          <p style={{textAlign:"right"}}><Button disabled>ändern</Button></p>
        </Card.Body>
      </Card>
    );*/
  }

  /*

  <Col md={{span:2}} className="mb-3">
    <center>
      {this.renderSymbolCard()}
    </center>
  </Col>

  */

  /**
   * Rendert die Hauptkomponente des "Profil"-Tabs
   */

   handleSaveUserText(text) {
     App.currentApp.state.user.setUserText(text);
     this.setState({newText: text});
   }

  renderChangeUserTextButton() {
    if(this.state.canChange)
      return(
        <p style={{textAlign:"right"}}>
          <ChangeShortTextModal
            showButton = {true}
            title = {'Profil Text'}
            placeholder = {'Neuer Profiltext'}
            handleSave={(text) => this.handleSaveUserText(text)}
          />
        </p>
    );
    return <></>;
  }

  renderProfileBody() {

    return(
      <Container>
      <center>
        <Row className="w-100 mb-3">
          <Col>
            {this.renderUserNameCard()}
          </Col>
        </Row>
        <Row className="w-100">

          <Col lg={{span: 12}} className="mb-3">
            <Card className="text-center" style={App.cardStyle}>
              <Card.Body>
                <p>{this.state.user.text}</p>
                {this.renderChangeUserTextButton()}
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="w-100">
          <Col>
            <Card>
              <Card.Header><b>{this.state.user.name}{Profil.labels.weeklyPostLabel}</b></Card.Header>
            </Card>
          </Col>
        </Row>
        <Row className="w-100">
          {this.renderOwnPost()}
        </Row>

        <Row className="mb-5" />

        </center>
      </Container>
    );
  }

  /**
   * Rendert den "Profil"-Tab mit Navigationsbar am unteren Ende.
   */
  render() {
    return (
      <Container className="pl-0 pr-0">
        {this.renderProfileBody()}
        <br/><br/>
        <Navbar />
      </Container>
    );
  }
}

export default Profil;
