import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Image from 'react-bootstrap/Image';

import './App.css';
import Profil from './Components/Profil.js';
import Login from './Components/Login';
import Topic from './Components/Topic';
import Feed from './Components/Feed';
import ErrorPage from './Components/ErrorPage';
import InfoPage from './Components/InfoPage';

import UserData from "./FirebaseData/UserData";
import TopicData from "./FirebaseData/TopicData";
import Firebase from './config.js';
import GeneralData from './FirebaseData/GeneralData';

import logo from './discover-logo-ohne-text.png';

class App extends React.Component
{
  static db_users = null;
  static db_topics = null;
  static Tabs = {"PROFILE":0, "TOPIC":1, "SEARCH":2, "FEED":3, "INFO":4};
  static db_posts = null;
  static db_general = null;

  static currentApp = null;

  static colorPalette = {
    primary: '#20441E',
  };

  static cardStyle = {
    borderStyle: 'solid',
    borderColor: App.colorPalette.primary,
  };

  static verticalCenter = {
    margin: 0,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  }

  static loadingMessage = 'Laden...';

  static initialProfileText = "Ä churzä Profiltext, maximal ei satz";

  constructor(props) {
    super(props);
    //Datenbank zu users
    App.db_users = Firebase.database().ref().child("users");
    App.db_topics = Firebase.database().ref().child("topics");
    App.db_posts = Firebase.database().ref().child("posts");
    App.db_general = Firebase.database().ref().child("general");
    this.state = {
      tab: App.Tabs.INFO,
      profileUser: null,
      user: null,
      loadingUser: false,
    };
    App.currentApp = this;
  }
  /**********************************************************************************************/
/** Mus Gebraucht werden für Jetzigen user zu laden */
  componentDidMount() {
    new GeneralData().loadGeneral(() => {
      new TopicData().loadTopic((topic) => {this.setState({topic: topic});}, (topic, error) => {console.log([topic, error]);});
    }, (topic, error) => {console.log([topic, error]);});

    this.unregisterAuthObserver = Firebase.auth().onAuthStateChanged((user) => this.onUserChanged(user));
  }

  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  loadFeed() {
    if(this.state.topic && this.state.user) {
      //new FeedData(this.state.topic).loadFeed(this.state.user, this.state.topic, (feed) => this.setState({feed: feed}), (feed, error) => console.log(error));
    }
  }

  onUserChanged(user) {
    if(user) {
      this.setState({loadingUser: true});
      this.loadUser(user);
    }
    else {
      this.setState({ user: null });
    }
  }

  async loadUser(user){
    await this.until(_ => TopicData.currentTopic);
    new UserData(user.uid).loadUser((user) => {this.setState({ user: user, loadingUser: false })}, (user, error) => {console.log([user, error]);}, true);
  }

  until(condition){
    const poll = resolve =>{
      if(condition()) resolve();
      else setTimeout(_ => poll(resolve), 400);
    }

    return new Promise(poll);
  }
  /**********************************************************************************************/

  setTab(tab) {
    this.setState({tab: tab})
  }

  logout() {
    Firebase.auth().signOut().then(() => {
      // Sign-out successful.
      this.setState({ user: null });
    }).catch((error) => {
      // An error happened.
    });
  }

  render() {

    let crumb = (
       <Breadcrumb>
         <span className="mr-5" >
            <Image width={100} src={logo} />
         </span>
         <Breadcrumb.Item active>
            <a href='https://www.discover-verein.ch/' target="_blank" rel="noreferrer">Homepage</a>
         </Breadcrumb.Item>
         <Breadcrumb.Item active>
            <a href='https://discover-forum.forumieren.de/' target="_blank" rel="noreferrer">Forum</a>
         </Breadcrumb.Item>
         <Breadcrumb.Item onClick={() => this.logout()}>Logout</Breadcrumb.Item>
       </Breadcrumb>

   );

   var firstLogin = false;

    //Kein user geladen --> this.state.user == null
    if(this.state.user)
    {
      //Namen nicht gelden --> this.state.user.name == null
      if(!this.state.user.name) {
        //Das erste Mal dass sich ein bestimmter Nutzer einloggt
        firstLogin = true;
        this.state.user.setUserName("Ä nöie Benutzer"); //Setzen des usernamen, wird auf datenbank geladen
      }
      if(!this.state.user.text) {
        this.state.user.setUserText(App.initialProfileText);
      }

      switch (this.state.tab) {
        case App.Tabs.INFO:
          return (
            <div className="pl-0 pr-0">
              {crumb}
              <InfoPage firstLogin={firstLogin}/>
            </div>
          );
        case App.Tabs.PROFILE:
          return (
            <div>
              {crumb}
              <Profil user = {this.state.profileUser ?? this.state.user} canChange = {this.state.profileUser==null}/>
            </div>

          );
          case App.Tabs.TOPIC:
            return (
              <div>
                {crumb}
                <Topic/>
              </div>
            );
          case App.Tabs.SEARCH:
            return (
              <div>
                {crumb}
                <ErrorPage app = {this}/>
              </div>
            );
          case App.Tabs.FEED:
            return (
              <div>
                {crumb}
                <Feed app = {this} />
              </div>
            );
        default:
      }
    }
    else
    {
      if(this.state.loadingUser) {

        return(
            <h3 style={App.verticalCenter}>
              {App.loadingMessage}
            </h3>
        );
      }
      return (<Login app = {this} />);
    }
  }
}

export default App;
