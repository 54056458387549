import React from "react";

import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';

class ChangeShortTextModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showButton: props.showButton,
      open: props.open,
      disableClose: props.disableClose,
      newText: '',
      placeholder: props.placeholder ?? '',
      title: props.title ?? '',
      handleSave: props.handleSave,
    }
  }

  /**
   * Wird aufgerufen wenn changeTextModal angezeigt werden soll
   */
  handleShow() {
    this.setState({open: true})
  }

  /**
   * Wird aufgerufen wenn changeTextModal nicht mehr angezeigt werden soll
   */
  handleClose(save) {
    if(save || !this.state.disableClose)
      this.setState({open: false})
  }

  /**
   * Handler für changeTextModal
   */
  handleChange(event) {
    this.setState({newText: event.target.value});
  }

  /**
   * Dieses Modal wird angezeigt wenn der Benutzer den Profiltext bearbeitet.
   */
  render() {

    const changeButton = <button className="discoverButton" onClick={() => this.handleShow()}>
      Ändere
    </button>

    const nothing = <></>;

    return(
      <Container>
        {this.state.showButton ? changeButton : nothing}

        <Modal show={this.state.open} onHide={() => this.handleClose()} centered>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <InputGroup>
            <FormControl
              placeholder={this.state.placeholder}
              aria-label={this.state.placeholder}
              aria-describedby="basic-addon2"
              onChange = {(event) => this.handleChange(event)}
            />
            <InputGroup.Append>
              <Button variant="outline-success" onClick={() => {this.state.handleSave(this.state.newText); this.handleClose(true)}}>Speichern</Button>
            </InputGroup.Append>
          </InputGroup>
          </Modal.Body>
        </Modal>
      </Container>
    );
  }

}

export default ChangeShortTextModal;
