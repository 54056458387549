import React from 'react';
import ReactTooltip from 'react-tooltip';

import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { PieChart } from 'react-minimal-pie-chart';

import App from "../App.js"
import UserData from "../FirebaseData/UserData";
import ColorSchematic from './ColorSchematic.js'
import TopicData from '../FirebaseData/TopicData.js'
import FeedData from '../FirebaseData/FeedData'
import ColorSchematicData from '../Data/ColorSchematicData'

class Statistics extends React.Component {

  static labels = {
    answersPerQuestion: 'Antworte pro Frag',
    popularCombinations: 'Beliebti Kombinatione',
  }

  constructor(props) {
    super(props);
    this.state = {
      feed: null,
    }
  }

  componentDidMount() {
    App.currentApp.loadFeed();
    new FeedData(TopicData.currentTopic).loadFeed(
      UserData.currentUser,
      TopicData.currentTopic,
      (feed) => {
        this.setState({feed: feed})
      },
      (feed, error) => console.log(error)
    );
  }

  makeTooltipContent(d) {
    return d.answer;
  }

  renderPieChart(d,i) {
    return(
      <div data-tip="" data-for={'chart_'+i} index={i}>
        <PieChart
          radius={4.5}
          label={(e)=>{return(Math.round(e.dataEntry.percentage))+'%'}}
          labelStyle={{fontSize:1}}
          data={d}
          viewBoxSize={[10,10]}
          center={[5,5]}
          onMouseOver={(_, index) => {
            this.setState({hoveredSector: index, hoveredPie: i});
          }}
          onMouseOut={() => {
            this.setState({hoveredSector: null, hoveredPie: null});
          }}
        />
        <ReactTooltip
          id={'chart_'+i}
          getContent={() =>
            typeof this.state.hoveredSector === 'number' && this.state.hoveredPie === i ? this.makeTooltipContent(d[this.state.hoveredSector]) : null
          }
        />
      </div>
    );
  }

  renderPieChartsCard() {
    const cardHeader = <Card.Header className="PrimaryColor">
                          <div className="d-flex justify-content-between">
                            <span>
                              {Statistics.labels.answersPerQuestion}
                            </span>
                          </div>
                        </Card.Header>;

    const count = this.state.feed.questionColorCount;

    var data = new Array(count.length);

    for (var i = 0; i < count.length; i++) {
      let index = 0;
      var data_i = [];
      for (var j = 0; j < count[i].length; j++) {
        if(count[i][j].count === 0)
          continue;
        data_i[index] = {
          title: count[i][j].color,
          value: count[i][j].count,
          color: ColorSchematicData.colorTemplate[count[i][j].color],
          answer: TopicData.currentTopic.questions[i].answers[j].title
        }
        index++;
      }
      data[i] = data_i;
    }

    const cardBody = <Card.Body>
                          <Row>
                            {data.map( (d,i) => {
                                return(
                                  <Col md={4}>
                                    {this.renderPieChart(d,i)}
                                    <p>
                                      <b>{TopicData.currentTopic.questions[i].title}</b>
                                    </p>
                                  </Col>
                                );
                              }
                          )}
                          </Row>
                          <br/>
                          <br/>
                          <br/>
                          <br/>
                      </Card.Body>;

    return(
      <Container className="w-100 mb-3">
        <Card>
          {cardHeader}
          {cardBody}
        </Card>
      </Container>
    );
  }

  renderCombinations() {
    const cardHeader = <Card.Header className="PrimaryColor">
                          <div className="d-flex justify-content-between">
                            <span>
                              {Statistics.labels.popularCombinations}
                            </span>
                          </div>
                        </Card.Header>;

    const count = this.state.feed.colorschematicCount;

    const cardBody = <Card.Body>
                        <ol>
                        {count.slice(0,3).map( (c,i) => {
                            return(
                              <li index={i}>

                                <ColorSchematic colorSchematicData = {c.schematic} />
                              </li>
                            );
                          }
                        )}
                        </ol>
                      </Card.Body>;

    return(
      <Container className="w-100 mb-3">
        <Card className="text-center">
          {cardHeader}
          {cardBody}
        </Card>
      </Container>
    );
  }

  render() {
      if(this.state.feed) {
        if(this.state.feed.questionColorCount.length !== 0) {
          return(
            <>
            <ReactTooltip

            />
              {
                this.renderPieChartsCard()
              }
              {
                this.renderCombinations()
              }
            </>
          );
        }
        else {
          return(<></>);
        }
      }
      else {
        return(
          <p>Auswertung Laden ...</p>
        );
      }
  }
}

export default Statistics;
