
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import 'firebaseui';

import App from './App.js';

// priduction


const DB_CONFIG = {
    apiKey: "AIzaSyD2aGqE6JCsrB_3F8X6nqRVntSscdHlnNY",
    authDomain: "mockup-48d65.firebaseapp.com",
    databaseURL: "https://mockup-48d65.firebaseio.com",
    projectId: "mockup-48d65",
    storageBucket: "mockup-48d65.appspot.com",
    messagingSenderId: "1067263125596",
    appId: "1:1067263125596:web:89ccc5ca28a29dd571de99"
  };


//testing
/*
var DB_CONFIG = {
    apiKey: "AIzaSyDWD5jDvJYfUqrNRnABaomUBmE35hvbtFg",
    authDomain: "discover-app-testing.firebaseapp.com",
    databaseURL: "https://discover-app-testing-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "discover-app-testing",
    storageBucket: "discover-app-testing.appspot.com",
    messagingSenderId: "1042682273551",
    appId: "1:1042682273551:web:0e42e56a0bbb99551da87a",
    measurementId: "G-7CRQ5NM552"
  };
*/
const UI_CONFIG = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: '/',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      {
        requireDisplayName: false,
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID
      },

      firebase.auth.GoogleAuthProvider.PROVIDER_ID,

    ],
      credentialHelper: 'none',
      callbacks: {
        signInSuccessWithAuthResult: function(currentUser, credential, redirectUrl) {
          App.currentApp.setState({loadingUser: true});
          return true;
        }
      }
};

const Firebase = firebase.initializeApp(DB_CONFIG);
require("firebase/auth");
require("firebase/database");

export default Firebase;
export {UI_CONFIG};
