import App from "../App.js"
import ColorSchematicData from "../Data/ColorSchematicData.js";
import TopicData from "./TopicData.js";
import PostData from "./PostData.js";

/** Klasse die Benutzerdaten enthält, ladet und in die Datenbank schreibt. */
class UserData {
    /**
     * Wird aufgerufen sobald der User die Daten von der Datenbank geladen hat.
     * @callback UserData~onUserLoaded
     * @param {UserData} user
     */

     /**
     * Wird aufgerufen sobald der User die Daten von der Datenbank geladen hat.
     * @callback UserData~onUserLoadError
     * @param {UserData} user
     * @param {Object} error
     */

    /** @typedef {number} UserData~Error Errorcode 0=NONE, 1=BUSY, 2=STRINGLENGTH, 3=ARGTYPE*/
    static Errors = {
        NONE:0,
        BUSY:1,
        STRINGLENGTH:2,
        ARGTYPE:3,
        FAIL:4};

    // Relative Referenzen zur Datenbank
    static nameRef = "name";
    static textRef = "profileText";
    static weightsRef = "weights";

     /** @type {UserData} Jetziger Benutzer. Statisch zugrefibar. */
    static currentUser = null;

    // Maximale Länge des Namens.
    static nameLengthMax = 32;
    // Maximale Länge des Profiltexts
    static textLengthMax = 256;

    /**
     * Bereitet einen User zum Laden vor.
     * @param {string} uid UID gegeben von Firebase
     */
    constructor(uid) {
        /** @type {string} Unique ID des Benutzers. */
        this.uid = uid;
        /** @type {boolean} Benutzerdaten sind am Laden. */
        this.busy = false;
        /** @type {boolean} Benutzerdaten sind geladen. */
        this.loaded = false;
        /** @type {string} Name des Benutzers. */
        this.name = null;
        /** @type {string} Profiltext des Benutzers. */
        this.text = null;
        /** @type {Array.<uid: string, weight: number>} Gewichtung spezifischer Benutzer.*/
        this.weights = null;
        /** @type {ColorSchematic} Gewichtung spezifischer Benutzer. */
        this.colorSchematic = null;
        /** @type {PostData} Post dieses Benutzers */
        this.post = null;
        /**
         * @namespace
         * @property {object} opinion Benutzer Meinung
         * @property {string} opinion.title Titel
         * @property {string} opinion.text Text
        */
        this.opinion = { title : null, text : null};
    }

    /**
     * Starten des Ladens der Benutzerdaten.
     * @param {UserData~onUserLoaded} onUserLoaded Callback für sobald die Daten geladen wurden
     * @param {UserData~onUserLoadError} onLoadError Callback falls ein Fehler auftritt
     * @param {bool} loadSelf Sollte der geladene benutzer in das statische Feld currentUser geladen werden
     * @returns {UserData~Error} Fehlercode
     */
    loadUser(onUserLoaded, onLoadError, loadSelf) {
        //Errohandling
        if(this.busy)
            return UserData.Errors.BUSY;
        try{
            this.db = App.db_users.child(this.uid);
            //Abfrage an die Datenbank
            this.busy = true;
            this.loaded = false;

            // Setzen des statischen Felds currentUser
            if(loadSelf !== undefined && loadSelf)
                UserData.currentUser = null;
            // Datenbank abfrage
            this.db.once("value")
                .then((data) => this.onLoadUser(data, onUserLoaded, loadSelf)) // Success
                .catch((error) => this.onLoadUserError(error, onLoadError)); // Fail
        }
        catch(error){
            onLoadError(this, error);
            return UserData.Errors.FAIL;
        }
        return UserData.Errors.NONE;
    }

    // Benutzerdaten wurden von Firebase abgerufen.
    onLoadUser(data, onUserLoaded, loadSelf) {
        //Setzen der Grunddaten
        this.name = data.child(UserData.nameRef).val();
        this.text = data.child(UserData.textRef).val();
        this.weights = [];

        //Setzen der Gewichtungen
        data.child(UserData.weightsRef).forEach((userWeight) => this.weights[userWeight.key] = userWeight.val());

        //Abschluss der Datenbankabfrage
        if(loadSelf !== undefined) {
            // Antworten des Benutzer ladens
            App.db_posts.child(TopicData.currentTopic.id)
                .child(this.uid).once("value") // Success
                .then((data) => this.onLoadAnswers(data, onUserLoaded)); // Fail
        } else {
            // Abschluss
            this.busy = false;
            this.loaded = true;
            if(onUserLoaded)
            onUserLoaded(this); //Callback
        }
    }

    // Benutzerantworten wurden von Firebase abgerufen.
    onLoadAnswers(data, onUserLoaded){
        // Überprüfen ob Antworten existieren, dann setzt alle Antworten zu den Fragen zum jetzigen Them
        if(data.hasChild(TopicData.answerRef))
            TopicData.currentTopic.questions.forEach((question) => question.currentAnswer = data.child(TopicData.answerRef).child(question.id).val() ?? -1);
        // Erstellen des Posts anhand der Antworten
        this.post = new PostData(this, data, this);
        // Setzen der Meinung
        this.opinion.title = this.post.title;
        this.opinion.text = this.post.text;
        // Abschluss
        UserData.currentUser = this;
        this.busy = false;
        this.loaded = true;
        if(onUserLoaded)
            onUserLoaded(this); //Callback
    }

    // Fehler beim Laden von Daten.
    onLoadUserError(error, onLoadError){
        //Abschluss der Datenbankabfrage
        this.busy = false;
        this.loaded = false;

        if(onLoadError)
            onLoadError(this, error); //Callback
    }

    /**
     * Ändern des Benutzernamens.
     * @param {string} name Der neue Name
     * @returns {UserData~Error} Fehlercode
     */
    setUserName(name) {
        //Errohandling
        if(this.busy)
            return UserData.Errors.BUSY;
        if(!(typeof name === "string"))
            return UserData.Errors.ARGTYPE;
        if(name.length > this.nameLengthMax)
            return UserData.Errors.STRINGLENGTH;

        //Setzen des Namens, auch in der Datenbank
        try{
            this.name = name;
            this.db.child(UserData.nameRef).set(name);
            if(this.post)
              this.post.author.name = name;
        } catch(error){
            console.log(error);
            return UserData.Errors.FAIL;
        }
        return UserData.Errors.NONE;
    }

    /**
     * Ändern des Profiltextes
     * @param {string} text Der neue Profiltext
     * @returns {UserData~Error} Fehlercode
     */
    setUserText(text) {
        //Errohandling
        if(this.busy)
            return UserData.Errors.busy;
        if(!(typeof text === "string"))
            return UserData.Errors.ARGTYPE;
        if(text.length > this.textLengthMax)
            return UserData.Errors.STRINGLENGTH;

        //Setzen des Textes, auch in der Datenbank
        this.text = text;
        this.db.child(UserData.textRef).set(text);
        return UserData.Errors.NONE;
    }

    /**
     * Ändern der Benutzergewichtung
     * @param {string} user UID des Benutzers der zu ändernden Gewichtung
     * @param {number} weight Neue Gewichtung
     * @returns {UserData~Error} Fehlercode
     */
    setUserWeight(user, weight) {
        //Errohandling
        if(this.busy)
            return UserData.Errors.busy;
        if(!(typeof user === "string"))
            return UserData.Errors.ARGTYPE;
        if(!(typeof weight === "number"))
            return UserData.Errors.ARGTYPE;

        //Setzen der Gewichtung, auch in der Datenbank
        this.weights[user] = weight;
        this.db.child(UserData.weightsRef).child(user).set(weight);
        return UserData.Errors.NONE;
    }

    /**
     * Gibt zurück ob der Benutzer eine Meinung abgegeben hat.
     * @returns {bool} Benutzer hat Meinung
     */
    hasOpinion() {
      //return this.post && (this.post.title || this.post.text);
      return this.post && this.post.text;
    }


    /**
     * Ändert die Meinung zur aktiven Frage
     * @param {AnswerData} answer
     * @param {string} user Benutzer objekt
     * @return {UserData~Error}
     */
     giveAnswer(answer){
         // Index der Antwort in der aktiven Frage finden
         let question = TopicData.currentTopic.currentQuestion;
        if(!question) {
            console.log('Cannot record this answer because current question is not set!');
            return UserData.Errors.FAIL;
        }
        let index = question.answers.indexOf(answer);
        if(index < 0)
            return UserData.Errors.FAIL;
        //Setzen der Antwort, auch in der Datenbank
        try{
            // Falls kein Farbschema existiert, eines kreieren
            question.currentAnswer = index;
            if(!this.post.ColorSchematicData)
                this.post.ColorSchematicData = new ColorSchematicData();
            this.post.ColorSchematicData.set(TopicData.currentTopic.questions.indexOf(question),index);
            // Setzen des Wertes in der Datenbank
            App.db_posts.child(TopicData.currentTopic.id).child(this.uid).child(TopicData.answerRef).child(question.id).set(index);
        } catch(error){
            console.log(error);
            return UserData.Errors.FAIL;
        }
        return UserData.Errors.NONE;
    }

    /**
     *
     * @param {string} title
     * @param {string} text
     * @param {string} user Benutzer objekt
     * @return {UserData~Error}
     */
    giveOpinion(title, text){
        //Setzen der Meinung, auch in der Datenbank
        try{
            this.post.title = title;
            this.post.text = text;
            App.db_posts.child(TopicData.currentTopic.id).child(this.uid).child("title").set(title);
            App.db_posts.child(TopicData.currentTopic.id).child(this.uid).child("text").set(text);
            App.db_posts.child(TopicData.currentTopic.id).child(this.uid).child("timestamp").set(Date.now());
        } catch(error){
            console.log(error);
            return UserData.Errors.FAIL;
        }

        return UserData.Errors.NONE;
    }

    /**
     * Entfernen der Meinung dieses Benutzers
     * @param {string} user Benutzer objekt
     * @return {UserData~Error}
     */
    removeOpinion(user){
        //Errohandling
        if(!this.loaded || this.busy) {
            console.log('User is busy!');
            return TopicData.Errors.BUSY;
        }
        //Löschen der Meinung, auch in der Datenbank
        try{
            user.post.title = null;
            user.post.text = null;
            App.db_posts.child(this.id).child(user.uid).remove();
        } catch(error){
            console.log(error);
            return UserData.Errors.FAIL;
        }

        return UserData.Errors.NONE;
    }

}

export default UserData
