import TopicData from "../FirebaseData/TopicData";

/** Klasse für Farbschema */
class ColorSchematicData{



    /**
     * Ein DatenSnaphsot.
     * @see {@link https://firebase.google.com/docs/reference/js/firebase.database.DataSnapshot?hl=EN}
     * @typedef {Object} DataSnapshot 
     */

  // Farbe zu Hexwert
   static colorTemplate = {
      "0": "#000000",
      "Traurig": "#FF5733",
      "Lustig": "#09AC1B",
      "grün":"#00FF00",
      "gelb":"#FFFF00",
      "rot":"#FF0000"
   };

   /**
    * Erstellt ein Farbschema anhand von einem DatenSnapshot
    * @param {DataSnapshot} colors Datensnapshot
    */
    constructor(colors) {

      var i;
      /** @type {int[]} Hexwert der Farben. */
      this.colors = [];
      this.index = [];
      if(colors === undefined)
        return;
      colors = colors.val();
      // Schleift durch alle Fragen
      for(i = 0; i < TopicData.currentTopic.questionCount; i++){
        // Der Index der Antwort bei jeder Frage in string
        var answer = TopicData.currentTopic.questions[i].answers[colors[TopicData.currentTopic.questions[i].id]];
        // Index der Antwort in number
        var index = colors[TopicData.currentTopic.questions[i].id];
        if(answer === undefined) {
          this.index[i] = 0;
          this.colors[i] = ColorSchematicData.colorTemplate["0"];
        } else {
          this.index[i] = index;
          this.colors[i] = ColorSchematicData.colorTemplate[answer.color];
        }
      }
    }

    /**
     * Gibt den Wert der Farbe anhand des Farbschlüssels aus
     * @param {string} colorName Farbschlüssel
     * @returns {string} Hexwert der Farbe
     */
    static getColor(colorName) {
      return ColorSchematicData.colorTemplate[colorName];
    }

    /**
     * Setzt ein Element der Frage
     * @param {number} index Index der Frage
     * @param {number} answerIndex Index der Antwort
     */
    set(index,answerIndex){
      var answer = TopicData.currentTopic.currentQuestion.answers[answerIndex];
      this.colors[index] = ColorSchematicData.colorTemplate[answer.color];
      this.index[index] = answerIndex;
    }
}

export default ColorSchematicData;
