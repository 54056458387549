import React from 'react';

import Firebase from '../config';
import {UI_CONFIG} from '../config';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';

import logo from '../discover-logo.png';

class Login extends React.Component
{

    constructor(props) {
      super(props);
      this.state = {app: props.app, showTitle: true};
      this.uiConfig = UI_CONFIG;
    }

    render()
    {
        //Display that the code is correct, in db codes
        return(
          <Container className="h-100 pr-0 pl-0">
            <Row className="h-50 m-5 justify-content-center align-items-center">
              <Col lg="6">
                <center>
                  <Image className="w-100" src={logo} />
                </center>
              </Col>
            </Row>
            <Row className="justify-content-center align-items-center">
              <Col lg="6">
                  <StyledFirebaseAuth uiConfig={UI_CONFIG} firebaseAuth={Firebase.auth()}/>
              </Col>
            </Row>
          </Container>
        );

    }

}

export default Login
