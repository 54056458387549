import App from "../App.js"

/** Klasse die Authordaten ladet. */
class AuthorData{
    /**
     * Wird aufgerufen sobald der User die Daten von der Datenbank geladen hat.
     * @callback AuthorData~onAuthorLoaded
     * @param {AuthorData} user
     */

     /**
     * Wird aufgerufen sobald der User die Daten von der Datenbank geladen hat.
     * @callback AuthorData~onAuthorLoadError
     * @param {AuthorData} user
     * @param {Object} error
     */

    /** @typedef {number} AuthorData~Error Errorcode 0=NONE, 1=BUSY, 2=STRINGLENGTH, 3=ARGTYPE*/
    static Errors = {
        NONE:0,
        BUSY:1,
        STRINGLENGTH:2,
        ARGTYPE:3,
        FAIL:4};

    /**
     * Bereitet den Author zum Laden vor
     * @param {string} uid UID des Authors
     * @param {UserData} [user] Schon bestehender Benutzer
     */
    constructor(uid, user){
        if(user !== undefined){ // Benutzer existiert bereits
            /** @type {string} Unique ID des Benutzers. */
            this.uid = user.uid;
            /** @type {boolean} Benutzerdaten sind am Laden. */
            this.busy = false;
            /** @type {boolean} Benutzerdaten sind geladen. */
            this.loaded = true;
            /** @type {string} Name des Benutzers. */
            this.name = user.name;
        } else { // Daten müssen noch geladen werden
            /** @type {string} Unique ID des Benutzers. */
            this.uid = uid;
            /** @type {boolean} Benutzerdaten sind am Laden. */
            this.busy = false;
            /** @type {boolean} Benutzerdaten sind geladen. */
            this.loaded = false;
            /** @type {string} Name des Benutzers. */
            this.name = null;
        }

    }

     /**
     * Starten des Ladens der Authordaten.
     * @param {AuthorData~onAuthorLoaded} onAuthorLoaded Callback für sobald die Daten geladen wurden
     * @param {AuthorData~onAuthorLoadError} onLoadError Callback falls ein Fehler auftritt
     * @returns {UserData~Error} Fehlercode
     */
    loadAuthor(onAuthorLoaded, onLoadError){
        //Errohandling
        if(this.busy)
            return AuthorData.Errors.BUSY;
        try{
            this.db = App.db_users.child(this.uid);
            //Abfrage an die Datenbank
            this.busy = true;
            this.loaded = false;
            this.db.child("name").once("value").then((data) => this.onLoadAuthor(data, onAuthorLoaded)).catch((error) => this.onLoadAuthorError(error, onLoadError));
        }
        catch(error){
            onLoadError(this, error);
            return AuthorData.Errors.FAIL;
        }
        return AuthorData.Errors.NONE;
    }

    // Authordaten wurden geladen
    onLoadAuthor(data, onAuthorLoaded){
        //Setzen der Grunddaten
        this.name = data.val();

        //Abschluss der Datenbankabfrage
        this.busy = false;
        this.loaded = true;

        if(onAuthorLoaded)
            onAuthorLoaded(this); //Callback
    }

    // Error beim Laden
    onLoadAuthorError(error, onLoadError){
        //Abschluss der Datenbankabfrage
        this.busy = false;
        this.loaded = false;

        if(onLoadError)
            onLoadError(this, error); //Callback
    }
}

export default AuthorData
