import App from "../App.js"
import ColorSchematicData from "../Data/ColorSchematicData.js";
import AuthorData from "./AuthorData.js";
import TopicData from "../FirebaseData/TopicData";

class PostData{

    static likesRef = "likes";

    constructor(ownUser, postData, user){
        this.author = new AuthorData(postData.ref.getKey(), user); // AuthorData.js
        if(postData.hasChild("answers"))
            this.ColorSchematicData = new ColorSchematicData(postData.child("answers"));
        else
            this.ColorSchematicData = null;
        this.title = postData.child("title").val();
        this.text = postData.child("text").val();
        this.link = postData.child("link").val();
        this.timeStamp = postData.child("timestamp").val();
        if(this.timeStamp === null)
            this.timeStamp = 0;
        this.likes = 0;
        this.hasLiked = false;
        if(postData.hasChild(PostData.likesRef))
          this.evaluateLikes(postData.child(PostData.likesRef), ownUser);
    }

    loadAuthor(callback){
        this.author.loadAuthor(callback, callback);
    }

    evaluateLikes(likesData, user){
        this.likes = likesData.numChildren();
        likesData.forEach((liker) => {if(liker.ref.getKey() === user.uid) this.hasLiked = true;})
    }

    toggleLike(user, topic) {
      try{
          if(this.hasLiked)
          {
            this.likes--;
            App.db_posts.child(topic.id).child(this.author.uid).child(PostData.likesRef).child(user.uid).remove();
          }
          else
          {
            this.likes++;
            App.db_posts.child(topic.id).child(this.author.uid).child(PostData.likesRef).child(user.uid).set(true);
          }
          this.hasLiked = !this.hasLiked;

      } catch(error){
          console.log(error);
          return TopicData.Errors.FAIL;
      }
      return TopicData.Errors.NONE;
    }
}

export default PostData
