import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import App from "../App.js"
import UserData from "../FirebaseData/UserData";
import ColorSchematic from './ColorSchematic.js'
import TopicData from '../FirebaseData/TopicData.js'
import GeneralData from '../FirebaseData/GeneralData.js'

//icons
import ThumbUpIcon from '@material-ui/icons/ThumbUp';

/** Komponente die einen Post rendert */
class Post extends React.Component {

   /**
   * @typedef {object} Post~state
   * @property {PostData} data Die PostData Klasse welche die zu rendernden Informationen enthält.
   * @property {boolean} likesEnabled true wenn der LikeButton aktiv sein soll
   * @property {boolean} showChange true wenn der Ändern-Button aktiv sein soll
   */

   static buttonLabels = {
      changeButton: 'Ändere',
      changeColorsButton: 'Meinig ändere',
      changeOpinionButton: 'Bitrag bearbeite',
      discussButton: 'bitrag diskutiere',
   };

   /**
    * Initialisation des States.
    * @param {Object} props properties die zum Rendern benötigt werden.
    */
  constructor(props) {
    super(props);
    /** @type {Post~state} Der state*/
    this.state = {
      data: props.postData,
      likesEnabled: props.likesEnabled,
      showChange: props.showChange,
      showChangeOpinion: props.showChangeOpinion,
      showChangeColors: props.showChangeColors,
      /** @type {TopicData} Die aktive TopicData instanz */
      topicComponent: props.topic,
      /** @type {int} 1 wenn der eingeloggte Benutzer diesen Post geliket hat, sonst 0*/
      localLikeBuffer: 0,
      onClickChange: props.onClickChange,
      onClickChangeOpinion: props.onClickChangeOpinion,
      onClickChangeColors: props.onClickChangeColors,
      index: props.index ?? -1,
    };
  }

  /**
   * Ausgeführt wenn der Ändern-Button geklickt wird.
   */
   change() {
     //App.currentApp.state.user.post.ColorSchematicData = null;
     this.state.onClickChange();
   }

  changeOpinion() {
    //App.currentApp.state.user.post.ColorSchematicData = null;
    this.state.onClickChangeOpinion();
  }

  changeColors() {
    //App.currentApp.state.user.post.ColorSchematicData = null;
    this.state.onClickChangeColors();
  }

  /**
   * Ausgeführt wenn der Like-Button geklickt wird. Updatet das PostData Objekt sowie den localLikeBuffer.
   */
  clickLike() {
    this.state.data.toggleLike(App.currentApp.state.user,TopicData.currentTopic);
    this.setState({localLikeBuffer: 1 - this.state.localLikeBuffer});
  }

  /**
   * Ausgeführt wenn der Author angeklickt wird. Ändert den Tab und schreibt den Author als profileUser in den State der globalen App Komponente.
   */
  clickAuthor() {
    new UserData(this.state.data.author.uid).loadUser(
      (user) => {
        user.post = this.state.data;
        App.currentApp.setState({tab: App.Tabs.PROFILE, profileUser: user});
      }
    );
  }

  render() {

    const linkStyle = {
      color:'#CEE6B4',
      background: 'none',
      border: 'none',
      padding: 0,
      textDecoration: 'underline',
      cursor: 'pointer',
    }

      const cardHeader = <Card.Header className="PrimaryColor">
                            <div className="d-flex justify-content-between">
                              <span>
                                Bitrag vo <button
                                              onClick={() => this.clickAuthor()}
                                              style={linkStyle}>
                                              {this.state.data.author.name}
                                            </button>
                              </span>
                              <ColorSchematic colorSchematicData={this.state.data.ColorSchematicData}
                                              topicData={TopicData.currentTopic}/>
                            </div>
                          </Card.Header>;

      const discussButton = <span>
                              <button className="discoverButton" onClick={() => {window.open(this.state.data.link)}}>
                                {Post.buttonLabels.discussButton}
                              </button>
                            </span>

      const cardBody = <Card.Body>
                            <div style={{maxHeight: '8.5em', overflow: 'scroll'}}>
                              <blockquote className="text-wrap blockquote mb-0">
                                {this.state.data.text}
                              </blockquote>
                            </div>
                        </Card.Body>;

      const changeButton = <span>
                              <button className="discoverButton" onClick={() => this.change()}>
                                {Post.buttonLabels.changeButton}
                              </button>
                            </span>;

      const changeOpinionButton = <span>
                              <button className="discoverButton" onClick={() => this.changeOpinion()}>
                                {Post.buttonLabels.changeOpinionButton}
                              </button>
                            </span>;

      const changeColorsButton = <span>
                              <button className="discoverButton" onClick={() => this.changeColors()}>
                                {Post.buttonLabels.changeColorsButton}
                              </button>
                            </span>;

      const PrimaryColor = {
        backgroundColor: '#20441E',
        color: 'white',
      }

      const SecondaryColor = {
        backgroundColor: 'white',
        color: 'black',
      }

      const likeButton = <span>
                          <Button variant={this.state.likesEnabled?"success":"secondary"}
                                  className={'mr-2'}
                                  style={this.state.data.hasLiked?PrimaryColor:SecondaryColor}
                                  disabled={!this.state.likesEnabled}
                                  onClick={ () => this.clickLike() }>
                            <ThumbUpIcon />
                          </Button>
                        </span>;

      const cardFooter = <Card.Footer>
                            <div className="d-flex justify-content-between">
                              {likeButton}
                              <span>
                              {this.state.showChange && changeButton}
                              {this.state.showChangeOpinion && changeOpinionButton}
                              {this.state.showChangeColors && changeColorsButton}
                              <span className="m-1" />
                              {(GeneralData.state.phase === "Diskutieren" && this.state.data.link!=null)  && discussButton}
                              </span>
                            </div>
                          </Card.Footer>;

      return(
        <Container className="w-100">
          <Card style={App.cardStyle}>
            {cardHeader}
            {cardBody}
            {cardFooter}
          </Card>
        </Container>
      );
  }
}

export default Post;
