import App from "../App.js"
import QuestionData from "../FirebaseData/QuestionData.js"
import GeneralData from "./GeneralData.js";

/** Klasse die Themen enthält, ladet und in die Benutzerantworten in die Datenbank schreibt. */
class TopicData{
    /**
     * Wird aufgerufen sobald der User die Daten von der Datenbank geladen hat.
     * @callback TopicData~onTopicLoaded
     * @param {TopicData} topic
     */

     /**
     * Wird aufgerufen sobald der User die Daten von der Datenbank geladen hat.
     * @callback TopicData~onTopicLoadError
     * @param {TopicData} topic
     * @param {Object} error
     */

    /** @typedef {number} TopicData~Error Errorcode 0=NONE, 1=BUSY, 2=STRINGLENGTH, 3=ARGTYPE*/
    static Errors = {
        NONE:0,
        BUSY:1,
        STRINGLENGTH:2,
        ARGTYPE:3,
        FAIL:4,
        NOTFOUND:5};

    // Relative Referenzen zur Datenbank
    static questionsRef = "questions";
    static titleRef = "title";
    static answerRef = "answers";
    static descriptionRef = "description";
    static videoUrlRef = "videoUrl";

    /** @type {TopicData} Jetziges Thema. Statisch zugrefibar. */
    static currentTopic = null;

    /**
     * Bereitet ein Thema zum Laden vor.
     */
    constructor(){
        /** @type {string} Titel des Themas. */
        this.title = null;
        /** @type {boolean} Benutzerdaten sind am Laden. */
        this.busy = false;
        /** @type {boolean} Benutzerdaten sind geladen. */
        this.loaded = false;
        /** @type {Arrray.<QuestionData>} Alle Fragen dieses Themas. */
        this.questions = [];
        /** @type {QuestionData} Aktive Frage. */
        this.currentQuestion = null;
        /** @type {number} Anzahl Fragen. */
        this.questionCount = 0;
        /** @type {number} Index der aktiven Frage. */
        this.questionIndex = 0;
        /** @type {boolean} Ist die jetzige Frage die Erste. */
        this.isFirstQuestion = false;
        /** @type {boolean} Ist die Jetzige Fage die Letzte. */
        this.isLastQuestion = false;
        /** @type {string} Datenbank ID des Themas. */
        this.id = null;
        this.videoUrl = null;
        this.description = null;
    }

    /**
     * Starten des Ladens der Benutzerdaten.
     * @param {TopicData~onTopicLoaded} onUserLoaded Callback für sobald die Daten geladen wurden
     * @param {TopicData~onTopicLoadError} onLoadError Callback falls ein Fehler auftritt
     * @returns {TopicData~Error} Fehlercode
     */
    loadTopic(onTopicLoaded, onLoadError){
        TopicData.currentTopic = null;
        //Errohandling
        if(this.busy)
            return TopicData.Errors.BUSY;
        try{
            //Abfrage an die Datenbank
            this.busy = true;
            this.loaded = false;
            App.db_topics.child(GeneralData.state.topic).once("value")
                .then((topic) => this.onLoadTopic(topic, onTopicLoaded)) // Success
                .catch((error) => this.onLoadTopicError(error, onLoadError)); // Fail
        }
        catch(error){
            onLoadError(this, error);
            return TopicData.Errors.FAIL;
        }
        return TopicData.Errors.NONE;
    }

    // Benutzerdaten wurden von Firebase abgerufen.
    onLoadTopic(data, onTopicLoaded) {
        // Setzen der Grunddaten
        this.title = data.child(TopicData.titleRef).val();
        this.description = data.child(TopicData.descriptionRef).val();
        this.videoUrl = data.child(TopicData.videoUrlRef).val();
        this.id = data.ref.getKey();
        // Laden der Fragen
        this.questions = [];
        var i = 0;
        data.child(TopicData.questionsRef).forEach((question) => {this.questions[i] = new QuestionData(question); i++;});
        // Setzen des Themas auf die Erste Frage
        this.questionCount = i;
        this.setQuestionIndex(0);

        //Abschluss der Datenbankabfrage
        this.busy = false;
        this.loaded = true;
        TopicData.currentTopic = this;
        if(onTopicLoaded)
            onTopicLoaded(this); //Callback
    }

    // Fehler beim Laden von Daten.
    onLoadTopicError(error, onLoadError) {
        //Abschluss der Datenbankabfrage
        this.busy = false;
        this.loaded = false;

        if(onLoadError)
            onLoadError(this, error); //Callback
    }

    /**
     * Setzt die nächste Frage als aktiv
     */
    nextQuestion(){
        this.setQuestionIndex(this.questionIndex + 1);
    }

    /**
     * Setzt die vorherige Frage als aktiv
     */
    previousQuestion(){
        this.setQuestionIndex(this.questionIndex - 1);
    }

    /**
     * Setzt die angegeben Fragennummber als aktiv
     * @param {number} newIndex
     */
    setQuestionIndex(newIndex){
        // Ist der neue Index gültig
        if(newIndex < 0 || newIndex >= this.questionCount)
            return;
        // Setzen der Fragendaten
        this.questionIndex = newIndex;
        this.currentQuestion = this.questions[this.questionIndex];
        // Überprüfen ob die jetzige Frage die erste oder die letze Frage ist
        this.isFirstQuestion = this.questionIndex === 0;
        this.isLastQuestion = this.questionIndex === this.questionCount - 1;
    }
}

export default TopicData
