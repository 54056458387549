import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import TopicData from '../FirebaseData/TopicData.js'

//icons
import {TiArrowForward,TiArrowBack} from 'react-icons/ti';

import Navbar from './Navbar/Navbar.js';
import Post from './Post.js'
import ColorSchematic from './ColorSchematic.js'
import App from '../App.js';
import Statistics from './Statistics.js';

import YouTube from 'react-youtube';
import UserData from '../FirebaseData/UserData.js';
import ColorSchematicData from '../Data/ColorSchematicData.js';

/** Komponente die den "Themen"-Tab rendert. */
class Topic extends React.Component {

  static Tabs = {'INITIAL':0,
                 'ANSWERING':1,
                 'WRITING':2,
                 'DONE':3}

  static buttonLabels = {
    saveOpinionButtonLabel: 'Bitrag spichere',
    answerQuestionsButtonLabel: 'Frage beantworte',
  }

  static labels = {
    topicOfTheWeekLabel: 'Thema vor Wuche:',
    yourColorSchemeLabel: 'Dis Farbschema',
    justificationLabel: 'Begründig',
    yourPost: 'Di Bitrag:',
    question: 'Frag',
  }

  constructor(props) {
    super(props);
    var tab = Topic.Tabs.INITIAL
    if(UserData.currentUser.post.ColorSchematicData !== null)
      tab = Topic.Tabs.WRITING;
    if(UserData.currentUser.hasOpinion())
      tab = Topic.Tabs.DONE;
    this.state = {
                  tab: tab,
                  opinionTitle: '',
                  opinionText: (UserData.currentUser && UserData.currentUser.loaded)?UserData.currentUser.post.text:'',
                  selectedAnswerIndex: TopicData.currentTopic.currentQuestion.currentAnswer,
                };
  }

  saveOpinion(e) {
    e.preventDefault();
    console.log(UserData.currentUser.giveOpinion(this.state.opinionTitle,this.state.opinionText));
    this.setState({tab: Topic.Tabs.DONE});
  }

  createPostForm() {
    return(
      <center>
        <Form onSubmit={(e)=> this.saveOpinion(e)}>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Control as="textarea"
                          rows={3}
                          value={this.state.opinionText}
                          placeholder={Topic.labels.justificationLabel}
                          onChange={e => this.setState({ opinionText: e.target.value })}
                          />
          </Form.Group>
          <button className="discoverButton" type="submit" disabled={!this.state.opinionText || this.state.opinionText===''}>
            {Topic.buttonLabels.saveOpinionButtonLabel}
          </button>
        </Form>
      </center>
    );
  }

  componentDidMount() {
    this.setState({hasOpinion : UserData.currentUser.hasOpinion()});
  }

  renderStatistics() {
    if(this.state.tab === Topic.Tabs.DONE)
      return(
        <Statistics className="w-100" />
      );
    else return(<></>);
  }


  topic(specificPart) {

    const opts = {
      height: '300',
      width: '75%',
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 0,
      },
    };

    return(
      <Container>
        <Card style={App.cardStyle}>
          <Card.Header className="PrimaryColor">
            {Topic.labels.topicOfTheWeekLabel}
          </Card.Header>
          <Card.Body>
            <h3>
              {App.currentApp.state.topic.title}
            </h3>
            <center>
              <YouTube className="m-3" videoId={'VYlJGoVAHjA'} opts = {opts}/>
            </center>
            <center>
              {App.currentApp.state.topic.description} <br/>
              Als Inspiration hesch hie d Essenz vom letzte <a href='https://www.discover-verein.ch/tisch' target="_blank" rel="noreferrer"> Discover-Tisch</a>

            </center>
            {specificPart}
          </Card.Body>
        </Card>
        <br/>
          {this.renderStatistics()}
        <br/>
      </Container>
    );
  }

  /**
   * Wird aufgerufen wenn der Button nach links geklickt wird.
   */
  onPreviousQuestion() {
    TopicData.currentTopic.previousQuestion();
    this.setState({index: TopicData.currentTopic.questionIndex,
                  answers: TopicData.currentTopic.currentQuestion.answers,
                  selectedAnswerIndex: TopicData.currentTopic.currentQuestion.currentAnswer});
  }

  /**
   * Wird aufgerufen wenn der Button nach rechts geklickt wird.
   */
  onNextQuestion() {
    if(this.state.selectedAnswerIndex===-1) {
      console.warn('Attempted to skip question without giving answer!');
      return;
    }
    console.log('Try to give answer:');
    console.log(UserData.currentUser.giveAnswer(TopicData.currentTopic.currentQuestion.answers[this.state.selectedAnswerIndex],App.currentApp.state.user));
    if(TopicData.currentTopic.isLastQuestion) {
      console.log('It was the last one.');
      this.setState({tab: Topic.Tabs.WRITING});
      TopicData.currentTopic.setQuestionIndex(0);
    } else {
      TopicData.currentTopic.nextQuestion();
    }
    this.setState({index: TopicData.currentTopic.questionIndex,
                   answers: TopicData.currentTopic.currentQuestion.answers,
                   selectedAnswerIndex: TopicData.currentTopic.currentQuestion.currentAnswer});
  }

  onClickAnswer(index) {
    if(index === this.state.selectedAnswerIndex) {
      this.setState({selectedAnswerIndex: TopicData.currentTopic.currentQuestion.currentAnswer});
    }
    else {
      this.setState({selectedAnswerIndex: index});
    }
  }

  answer(index, color = '') {
    return(
      <Card key={index} className={'mt-1'} onClick={() => this.onClickAnswer(index)} style={{backgroundColor: ColorSchematicData.getColor(TopicData.currentTopic.currentQuestion.answers[index].color), opacity: (this.state.selectedAnswerIndex === index? 1.0 : .4)}}>
        <Card.Body>
          <span>{TopicData.currentTopic.currentQuestion.answers[index].title}</span>
        </Card.Body>
      </Card>
    );
  }

  /**
   * Rendert eine Frage mit drei Antwortmöglichkeiten und den Navigationsknöpfen.
   */
  question(cardclass="",header=true) {

    //let topic = App.currentApp.state.topic;
    var question = TopicData.currentTopic.currentQuestion.title;

    const nextButton = <button
                          className="discoverButton"onClick={ () => this.onNextQuestion() }
                          disabled={this.state.selectedAnswerIndex === -1}
                        >
      <TiArrowForward />
    </button>

    const previousButton = <button className="discoverButton" onClick={ () => this.onPreviousQuestion() } disabled={TopicData.currentTopic.isFirstQuestion}>
      <TiArrowBack/>
    </button>

    return(
      <Container>
        <Card style={App.cardStyle} className={cardclass + ' mb-3'}>

          <Card.Header className="PrimaryColor">
            <span>{Topic.labels.question} {TopicData.currentTopic.questionIndex + 1}/{TopicData.currentTopic.questionCount}</span>
          </Card.Header>

          <Card.Body>
            <Card.Title>
              <span>{question}</span>
            </Card.Title>
          </Card.Body>
        </Card>

        {
          //TODO
          [0,1,2].map( (ans) => this.answer(ans) )
        }

        <div className="d-flex justify-content-between mt-1">
          {previousButton}
          {nextButton}
        </div>
      </Container>
    );
  }

  /**
   * Rendert den "Themen"-Tab mit Navigationsbar am unteren Ende.
   */
  render() {
    const specificInitial = <center>
      <br/>
      <button className="discoverButton" onClick = {() => this.setState({tab: Topic.Tabs.ANSWERING})}>
        {Topic.buttonLabels.answerQuestionsButtonLabel}
      </button>
      <br/>
    </center>

    const specificWriting = <Container>
      <center>
        <b> {Topic.labels.yourColorSchemeLabel} </b>
        <ColorSchematic colorSchematicData={UserData.currentUser.post.ColorSchematicData} topicData={TopicData.currentTopic}/>
        {this.createPostForm()}
      </center>
    </Container>

    const specificDone = <Container>
      <b>{Topic.labels.yourPost}</b>
      <br/>
      <br/>
      <Post app={App.currentApp} postData={UserData.currentUser.post} topic={this}
      showChangeOpinion={true}  onClickChangeOpinion={() => this.setState({tab : Topic.Tabs.WRITING})}
      showChangeColors={true} onClickChangeColors={() => this.setState({tab : Topic.Tabs.ANSWERING})}/>
      </Container>

    switch (this.state.tab) {
      case Topic.Tabs.INITIAL:
        return(
          <Container>
            {this.topic(specificInitial)}
            <br/><br/>
            <br/><br/>
            <br/><br/>
            <Navbar />
          </Container>
        );
      case Topic.Tabs.WRITING:
        return(
          <Container>
            {this.topic(specificWriting)}
            <br/><br/>
            <br/><br/>
            <br/><br/>
            <Navbar />
          </Container>
        );
      case Topic.Tabs.ANSWERING:
        return(
          <Container>
            {this.question()}
            <br/><br/><br/><br/>
            <Navbar />
          </Container>
          );
      case Topic.Tabs.DONE:
        return(
          <Container>
            {this.topic(specificDone)}
            <br/><br/><br/><br/>
            <Navbar />
          </Container>
          );
      default:

    }
    return (
      <Container>


      </Container>
    );
  }
}
export default Topic;
