import React from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import Navbar from './Navbar/Navbar.js';

import Post from './Post.js';
import FeedData from '.././FirebaseData/FeedData'
import UserData from '.././FirebaseData/UserData'
import TopicData from '.././FirebaseData/TopicData'
import GeneralData from '.././FirebaseData/GeneralData'
import App from '../App'

class Feed extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: props.feedData
    };
  }

  componentDidMount() {
    App.currentApp.loadFeed();
    new FeedData(TopicData.currentTopic).loadFeed(UserData.currentUser, TopicData.currentTopic, (feed) => this.setState({feed: feed}), (feed, error) => console.log(error));
  }

  renderPost(postData, index) {
    if(postData.text) {
      return(
        <Container key={index} className="pl-0 pr-0">
          <Post
            divclass=''
            open=''
            postData={postData}
            likesEnabled={!GeneralData.state.isDiscussion() && postData.author.uid !== UserData.currentUser.uid}
            index={index}/>
          <br/>
        </Container>
      );
    } else {
      return (<></>);
    }

  }

  renderFeedBody() {
    if(this.state.feed) {
      if(this.state.feed.posts.length === 0) {
        return(
          <center>
            <h2>
              Leider het no niemer sini Meinig teilt.
            </h2>
          </center>
        );
      }
      return(
        <Container className="pl-0 pr-0">
          <Row className="w-100 mb-3">
            <Col>
              {this.state.feed.posts.map( (postData, index) => this.renderPost(postData, index) )}
            </Col>
          </Row>
        </Container>
      );
    } else {
      return(
        <h3 style={App.verticalCenter}>
          {App.loadingMessage}
        </h3>
      );
    }
  }

  render() {
    return (
      <Container className="pl-0 pr-0">
        {this.renderFeedBody()}
        <br/><br/><br/><br/>
        <Navbar app={this.state.app} divclass="bg-dark fixed-bottom" button="dark" />
      </Container>
    );
  }
}

export default Feed;
